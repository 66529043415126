import React from "react"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Não encontrado" />
    <div className="not-found text-center">
      <h1>
        Ooops...{" "}
        <span role="img" aria-label="Emoji">
          😡
        </span>
      </h1>
      <h3>...esta página não existe...</h3>
    </div>
  </Layout>
)

export default NotFoundPage
